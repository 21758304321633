/* eslint-disable eqeqeq */
<template>
  <div class="background">
    <div class="z_nav">
      <div class="typeArea ul">
        <div class="a active" @click="tomyCollectionarticles()">我的收藏</div>
      <div class="a" @click="tomyRecentInformation()">近期浏览</div>
      </div>
    </div>
    <div class="typeArea content">
      <div class="z_index">
        <div class="left">
          <div
        class="content-top"
        v-if="myCollectionguidelist.length>0"
      >
        <div class="content-item project">
          <!-- <div class="header">
            <div class="header-left">
              <div class="vertical-line"></div>
              <div>我收藏的指南</div>
            </div>
          </div> -->
          <div class="content-main">
            <table style="display:none;">
              <thead>
                <tr>
                  <td>文章标题</td>
                  <td>机构</td>
                  <td>关键词</td>
                  <td>关注时间</td>
                  <td>功能</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item) in myCollectionguidelist"
                  :key="item.id"
                >

                  <td
                    :title="item.title"
                    @click="toReportingGuideDetail(item)"
                  >{{item.title}}</td>
                  <td> <template v-if="item.government!==false">
                      {{item.government}}
                    </template> <template v-if="item.government===false">
                      ——
                    </template></td>
                  <td :title="item.keywords"> <span v-if="item.keywords===false">——</span> <span v-else>{{item.keywords}}</span></td>
                  <td>{{item.formatecreate}}</td>
                  <td>
                    <div
                      class="button-see"
                      @click="unsubscribe(item)"
                    >取消收藏</div>
                  </td>
                </tr>
              </tbody>
            </table>
             <div class="content-item1"  v-for="(item) in myCollectionguidelist"
                  :key="item.id">
                    <div class="item-content">
                    <div
                      class="content-title"
                      @click="toReportingGuideDetail(item)"
                    >
                    <!-- <div
                    v-if="item.HITS>700"
                    class="item-tag re"
                  >
                  热
                  </div> -->
                  <!-- <div
                    v-if="item.times > 0"
                    class="item-tag zhi"
                  >
                  止
                  </div> -->
                      <div class="title-text">{{ item.PROJECT_NAME }}</div>
                    </div>
                    <div class="content-info">
                      <div class="info-item long">
                        <div class="item-describe">发布机构：</div>
                        <div class="item-info">
                          <span
                            v-if="
                              item.PROJECT_GOVERNMENT == null ||
                              item.PROJECT_GOVERNMENT == ''
                            "
                            >——</span
                          >
                          <span class="fbjg" v-else>{{
                            item.PROJECT_GOVERNMENT
                          }}</span>
                        </div>
                      </div>
                      <div class="info-item">
                        <div class="item-describe">资助力度：</div>
                        <div class="item-info">
                          <span
                            v-if="
                              item.PROJECT_FUNDS == '' ||
                              item.PROJECT_FUNDS == null
                            "
                            >——</span
                          ><span v-else>{{ item.PROJECT_FUNDS }}万元</span>
                        </div>
                      </div>
                      <div class="info-item">
                        <div class="item-describe">发布时间：</div>
                        <div class="item-info">{{ item.PROJECT_DATE }}</div>
                      </div>
                      <div class="info-item long">
                        <div class="item-describe">资助范围：</div>
                        <div class="item-info">
                          <span v-if="item.PROJECT_GOVERNMENT_PRO">{{
                            item.PROJECT_GOVERNMENT_PRO
                          }}</span>
                          <span v-else>——</span>
                        </div>
                      </div>
                      <div class="info-item">
                        <div class="item-describe">截至时间：</div>
                        <div class="item-info">{{ item.PROJECT_DATE_END }}</div>
                      </div>
                       <div class="info-item">
                        <div class="item-describe">功能：</div>
                         <div
                      class="button-see"
                      @click="unsubscribe(item)"
                    >取消收藏</div>
                      </div>
                    </div>
                    <div class="content-tag">
                      <div
                        class="tag-item"
                        v-for="(ite, index) in item.PROJECT_INDUSTRY"
                        :key="index"
                      >
                        {{ ite }}
                      </div>
                    </div>
                  </div>
                  </div>
          </div>
        </div>
      </div>
      <div
        class="content-top"
        v-if="myCollecttenderlist.length>0"
        style="display:none;"
      >
        <div class="content-item project">
          <div class="header">
            <div class="header-left">
              <div class="vertical-line"></div>
              <div>我收藏的招标</div>
            </div>
          </div>
          <div class="content-main">
            <table style="display:none;">
              <thead>
                <tr>
                  <td>文章标题</td>
                  <td>机构</td>
                  <td>关键词</td>
                  <td>关注时间</td>
                  <td>功能</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item) in myCollecttenderlist"
                  :key="item.id"
                >

                  <td
                    :title="item.title"
                    @click="toNewTenderDetail(item)"
                  >{{item.title}}</td>
                  <td> <template v-if="item.government!==false">
                      {{item.government}}
                    </template> <template v-if="item.government===false">
                      ——
                    </template></td>
                  <td :title="item.keywords"> <span v-if="item.keywords===false">——</span> <span v-else>{{item.keywords}}</span></td>
                  <td>{{item.formatecreate}}</td>
                  <td>
                    <div
                      class="button-see"
                      @click="unsubscribe(item)"
                    >取消收藏</div>
                  </td>
                </tr>
              </tbody>
            </table>
             <div class="content-item1"  v-for="(item) in myCollecttenderlist"
                  :key="item.id">
                    <div class="item-content">
                    <div
                      class="content-title"
                      @click="toNewTenderDetail(item)"
                    >
                      <div class="title-text">{{ item.title }}</div>
                    </div>
                    <div class="content-info">
                      <div class="info-item long">
                        <div class="item-describe">发布机构：</div>
                        <div class="item-info">
                          <span
                            v-if="
                              item.government == null ||
                              item.government == ''
                            "
                            >——</span
                          >
                          <span class="fbjg" v-else>{{
                            item.government
                          }}</span>
                        </div>
                      </div>
                      <!-- <div class="info-item">
                        <div class="item-describe">资助力度：</div>
                        <div class="item-info">
                          <span
                            v-if="
                              item.PROJECT_FUNDS == '' ||
                              item.PROJECT_FUNDS == null
                            "
                            >——</span
                          ><span v-else>{{ item.PROJECT_FUNDS }}万元</span>
                        </div>
                      </div> -->
                      <div class="info-item">
                        <div class="item-describe">关注时间：</div>
                        <div class="item-info">{{ item.formatecreate }}</div>
                      </div>
                      <!-- <div class="info-item long">
                        <div class="item-describe">资助范围：</div>
                        <div class="item-info">
                          <span v-if="item.PROJECT_GOVERNMENT_PRO">{{
                            item.PROJECT_GOVERNMENT_PRO
                          }}</span>
                          <span v-else>——</span>
                        </div>
                      </div> -->
                      <!-- <div class="info-item">
                        <div class="item-describe">截至时间：</div>
                        <div class="item-info">{{ item.PROJECT_DATE_END }}</div>
                      </div> -->
                       <div class="info-item">
                        <div class="item-describe">功能：</div>
                         <div
                      class="button-see"
                      @click="unsubscribe(item)"
                    >取消收藏</div>
                      </div>
                    </div>
                    <div class="content-tag" style="line-height:1.5;">
                      <!-- <div
                        class="tag-item"
                        v-for="(ite, index) in item.keywords"
                        :key="index"
                      >
                        {{ ite }}
                      </div> -->
                      {{item.keywords}}
                    </div>
                  </div>
                  </div>
          </div>
        </div>
      </div>
      <div
        class="content-top"
        v-if="myCollectprojectlist.length>0"
         style="display:none;"
      >
        <div class="content-item project">
          <div class="header">
            <div class="header-left">
              <div class="vertical-line"></div>
              <div>我收藏的项目</div>
            </div>
          </div>
          <div class="content-main">
            <table style="display:none;">
              <thead>
                <tr>
                  <td>文章标题</td>
                  <td>机构</td>
                  <td>关键词</td>
                  <td>关注时间</td>
                  <td>功能</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item) in myCollectprojectlist"
                  :key="item.id"
                >

                  <td
                    :title="item.title"
                    @click="toGlobalResearchDetail(item)"
                  >{{item.title}}</td>
                  <td> <template v-if="item.government!==false">
                      {{item.government}}
                    </template> <template v-if="item.government===false">
                      ——
                    </template></td>
                  <td :title="item.keywords"> <span v-if="item.keywords==null">——</span> <span v-else>{{item.keywords}}</span></td>
                  <td>{{item.formatecreate}}</td>
                  <td>
                    <div
                      class="button-see"
                      @click="unsubscribe(item)"
                    >取消收藏</div>
                  </td>
                </tr>
              </tbody>
            </table>
              <div class="content-item1"  v-for="(item) in myCollectprojectlist"
                  :key="item.id">
                    <div class="item-content">
                    <div
                      class="content-title"
                      @click="toGlobalResearchDetail(item)"
                    >
                      <div class="title-text">{{ item.title }}</div>
                    </div>
                    <div class="content-info">
                      <div class="info-item long">
                        <div class="item-describe">发布机构：</div>
                        <div class="item-info">
                          <span
                            v-if="
                              item.government == null ||
                              item.government == ''
                            "
                            >——</span
                          >
                          <span class="fbjg" v-else>{{
                            item.government
                          }}</span>
                        </div>
                      </div>
                      <!-- <div class="info-item">
                        <div class="item-describe">资助力度：</div>
                        <div class="item-info">
                          <span
                            v-if="
                              item.PROJECT_FUNDS == '' ||
                              item.PROJECT_FUNDS == null
                            "
                            >——</span
                          ><span v-else>{{ item.PROJECT_FUNDS }}万元</span>
                        </div>
                      </div> -->
                      <div class="info-item">
                        <div class="item-describe">关注时间：</div>
                        <div class="item-info">{{ item.formatecreate }}</div>
                      </div>
                      <!-- <div class="info-item long">
                        <div class="item-describe">资助范围：</div>
                        <div class="item-info">
                          <span v-if="item.PROJECT_GOVERNMENT_PRO">{{
                            item.PROJECT_GOVERNMENT_PRO
                          }}</span>
                          <span v-else>——</span>
                        </div>
                      </div> -->
                      <!-- <div class="info-item">
                        <div class="item-describe">截至时间：</div>
                        <div class="item-info">{{ item.PROJECT_DATE_END }}</div>
                      </div> -->
                       <div class="info-item">
                        <div class="item-describe">功能：</div>
                         <div
                      class="button-see"
                      @click="unsubscribe(item)"
                    >取消收藏</div>
                      </div>
                    </div>
                    <div class="content-tag" style="line-height:1.5;">
                      <!-- <div
                        class="tag-item"
                        v-for="(ite, index) in item.keywords"
                        :key="index"
                      >
                        {{ ite }}
                      </div> -->
                      {{item.keywords}}
                    </div>
                  </div>
                  </div>
          </div>
        </div>
      </div>

      <div
        class="content-top"
        v-if="myCollectionarticleslist.length>0"
         style="display:none;"
      >
        <div class="content-item new-project">
          <div class="header">
            <div class="header-left">
              <div class="vertical-line"></div>
              <div>我收藏的文章</div>
            </div>
          </div>
          <div class="content-main">
            <table style="display:none;">
              <thead>
                <tr>
                  <td>文章标题</td>
                  <td>关键词</td>
                  <td>关注时间</td>
                  <td>功能</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item) in myCollectionarticleslist"
                  :key="item.id"
                >

                  <td
                    :title="item.title"
                    @click="depthOfTheArticleDetails(item.id)"
                  >{{item.title}}</td>
                  <td :title="item.keywords"> <span v-if="item.keywords===false">——</span> <span v-else>{{item.keywords}}</span></td>
                  <td>{{item.formatecreate}}</td>
                  <td>
                    <div
                      class="button-see"
                      @click="unsubscribe(item)"
                    >取消收藏</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="content-item1"  v-for="(item) in myCollectionarticleslist"
                  :key="item.id">
                    <div class="item-content">
                    <div
                      class="content-title"
                      @click="depthOfTheArticleDetails(item.id)"
                    >
                      <div class="title-text">{{ item.title }}</div>
                    </div>
                    <div class="content-info">
                      <!-- <div class="info-item long">
                        <div class="item-describe">发布机构：</div>
                        <div class="item-info">
                          <span
                            v-if="
                              item.government == null ||
                              item.government == ''
                            "
                            >——</span
                          >
                          <span class="fbjg" v-else>{{
                            item.government
                          }}</span>
                        </div>
                      </div> -->
                      <!-- <div class="info-item">
                        <div class="item-describe">资助力度：</div>
                        <div class="item-info">
                          <span
                            v-if="
                              item.PROJECT_FUNDS == '' ||
                              item.PROJECT_FUNDS == null
                            "
                            >——</span
                          ><span v-else>{{ item.PROJECT_FUNDS }}万元</span>
                        </div>
                      </div> -->
                      <div class="info-item">
                        <div class="item-describe">关注时间：</div>
                        <div class="item-info">{{ item.formatecreate }}</div>
                      </div>
                      <!-- <div class="info-item long">
                        <div class="item-describe">资助范围：</div>
                        <div class="item-info">
                          <span v-if="item.PROJECT_GOVERNMENT_PRO">{{
                            item.PROJECT_GOVERNMENT_PRO
                          }}</span>
                          <span v-else>——</span>
                        </div>
                      </div> -->
                      <!-- <div class="info-item">
                        <div class="item-describe">截至时间：</div>
                        <div class="item-info">{{ item.PROJECT_DATE_END }}</div>
                      </div> -->
                       <div class="info-item">
                        <div class="item-describe">功能：</div>
                         <div
                      class="button-see"
                      @click="unsubscribe(item)"
                    >取消收藏</div>
                      </div>
                    </div>
                    <div class="content-tag" style="line-height:1.5;">
                      <!-- <div
                        class="tag-item"
                        v-for="(ite, index) in item.keywords"
                        :key="index"
                      >
                        {{ ite }}
                      </div> -->
                      {{item.keywords}}
                    </div>
                  </div>
                  </div>
          </div>
        </div>
      </div>
      <!-- <div
        class="content-top nocollection"
        v-if="myCollectionarticleslist.length===0&&myCollecttenderlist.length===0&&myCollectionguidelist.length===0&&myCollectprojectlist.length===0"
      > -->
      <div
        class="content-top nocollection"
        v-if="myCollectionguidelist.length===0"
        style="flex-direction: column;"
      >
        <img
          src="../../../assets/image/我的订阅_slices/未订阅1.png"
          alt=""
          style="max-width:88%;"
        >
        <!-- <div style="font-size:20px;color:#282828;line-height:1.5;margin:110px 0;text-align: center;">
                您还有没订阅任何内容，请点击右上角<br>“+ 新标签”按钮进行添加
              </div> -->
      </div>
        </div>
        <div class="right">
          <div class="z_xgkt">
          <div class="title">推荐阅读</div>
          <ul>
            <Li
              v-for="(item, index) in tonghang.slice(0, 5)"
              :key="index"
              @click="toReportingGuideDetail(item)"
            >
              <h1>{{ item.PROJECT_NAME }}</h1>
              <h2>{{ item.PROJECT_DATE_END }}</h2>
            </Li>
          </ul>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { CollectList, CollectDelete } from '../../../api/myCollectionarticles'
import { formatDate } from '../../../util/tools'
import { Addhistoricalrecord } from '../../../api/myRecentInformation'
export default {
  data () {
    return {
      reportingGuidelinestotal: 3,
      ReportingGuidelinesList: [{ id: 1 }],
      // 收藏的文章列表
      myCollectionarticleslist: [],
      // 收藏的指南列表
      myCollectionguidelist: [],
      // 收藏的招标列表
      myCollecttenderlist: [],
      // 收藏的项目列表
      myCollectprojectlist: [],
      tonghang: [],
    }
  },
  created () {
    this.getData()
    this.getPeerReview()
    this.navxuanzhong()
  },
  methods: {
    navxuanzhong(){
      localStorage.setItem("navActive", 0);
      this.$store.commit("setnavActive", 0);
    },
    //同行再看
    getPeerReview() {
      const that = this;
      const axios = require("axios");
      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      const appurl = "https://data.keyanpro.com/keyanpro3/";
      formData.append("user_id", this.$store.state.userid); //添加文件对象 ，data中设置的
      axios({
        url: appurl + "PeerReview",
        method: "post",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (res) {
          console.log(res);
          that.tonghang = res.data.resultList;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 获取初始化数据
    getData () {
      this.getCollectList()
    },
    // 获取收藏列表数据
    getCollectList () {
      const id = this.$store.state.userid
      const params = {
        user_id: id
      }
      CollectList(params).then(res => {
        console.log('收藏文章列表', res)
        this.toreportingGuidelines(res.data)
      })
    },
    // 根据type类型拆分数据
    toreportingGuidelines (arr) {
      const arr4 = []
      const arr1 = []
      const arr2 = []
      const arr3 = []
      arr.forEach(element => {
        element.formatecreate = formatDate(element.crerate_time)
        // eslint-disable-next-line eqeqeq
        if (element.type == 4) {
          arr4.push(element)
          // eslint-disable-next-line eqeqeq
        } else if (element.type == 1) {
          arr1.push(element)
          // eslint-disable-next-line eqeqeq
        } else if (element.type == 2) {
          arr2.push(element)
          // eslint-disable-next-line eqeqeq
        } else if (element.type == 3) {
          arr3.push(element)
        }
      })
      this.$data.myCollectionarticleslist = arr4
      this.$data.myCollectionguidelist = arr1
      this.$data.myCollecttenderlist = arr2
      this.$data.myCollectprojectlist = arr3
    },
    // 取消收藏
    unsubscribe (item) {
      const that = this
      const userid = this.$store.state.userid
      const tid = item.IN_PROJECT_GOV_ID
      const type = item.type
      const params = {
        title_id: tid,
        type: type,
        user_id: userid
      }
      CollectDelete(params).then((res) => {
        console.log('数据删除返回', res)
        // eslint-disable-next-line eqeqeq
        if (res.code == 1) {
          that.$message({
            message: '取消收藏成功',
            type: 'success'
          })
          const arr4 = that.$data.myCollectionarticleslist
          const arr1 = that.$data.myCollectionguidelist
          const arr2 = that.$data.myCollecttenderlist
          const arr3 = that.$data.myCollectprojectlist
          // eslint-disable-next-line eqeqeq
          if (item.type == 4) {
            for (let i = 0; i < arr4.length; i++) {
              // eslint-disable-next-line eqeqeq
              if (arr4[i].id == item.id) {
                arr4.splice(i, 1)
              }
            }
            that.$data.myCollectionarticleslist = arr4
            // eslint-disable-next-line eqeqeq
          } else if (item.type == 1) {
            for (let i = 0; i < arr1.length; i++) {
              // eslint-disable-next-line eqeqeq
              if (arr1[i].id == item.id) {
                arr1.splice(i, 1)
              }
            }
            that.$data.myCollectionguidelist = arr1
            // eslint-disable-next-line eqeqeq
          } else if (item.type == 2) {
            for (let i = 0; i < arr2.length; i++) {
              // eslint-disable-next-line eqeqeq
              if (arr2[i].id == item.id) {
                arr2.splice(i, 1)
              }
            }
            that.$data.myCollecttenderlist = arr2
            // eslint-disable-next-line eqeqeq
          } else if (item.type == 3) {
            for (let i = 0; i < arr3.length; i++) {
              // eslint-disable-next-line eqeqeq
              if (arr3[i].id == item.id) {
                arr3.splice(i, 1)
              }
            }
            that.$data.myCollectprojectlist = arr3
          }
        } else {
          that.$message({
            message: '数据删除失败',
            type: 'warning'
          })
        }
      })
      window._czc.push(['_trackEvent', '点击', '取消收藏'])
    },
    // 跳转到最新课题申报信息详情页
    toReportingGuideDetail (item) {
      localStorage.setItem('navActive', 2)
      this.$store.commit('setnavActive', 2)
      const id = item.IN_PROJECT_GOV_ID
      const uid = this.$store.state.userid
      const params = {
        title_id: id,
        user_id: uid
        // type: 1
      }
      Addhistoricalrecord(params).then((res) => {
        console.log('添加历史记录成功', res)
      }, (err) => {
        console.log('添加历史记录异常', err)
      })
      let md = item.PROJECT_GOV_MD5
      if (md === null) {
        md = ''
      }
      debugger
      this.$router.push({
        // name: "politicsInTheTenderDetails",
        name: "reportingGuidelinesDetails",
        params: {
          id: id,
        },
        // query: {
        //   TENDER_MD5: md,
        // },
      });
      window._czc.push(['_trackEvent', '点击', '跳转到最新课题申报详情页'])
    },
    // 跳转到最新政采招标信息详情页
    toNewTenderDetail (item) {
      localStorage.setItem('navActive', 3)
      this.$store.commit('setnavActive', 3)
      const id = item.TENDER_ID
      const uid = this.$store.state.userid
      const params = {
        title_id: id,
        user_id: uid
        // type: 1
      }
      Addhistoricalrecord(params).then((res) => {
        console.log('添加历史记录成功', res)
      }, (err) => {
        console.log('添加历史记录异常', err)
      })
      let md = item.TENDER_MD5
      if (md === null) {
        md = ''
      }
      this.$router.push({
        name: 'reportingGuidelinesDetails',
        params: {
          id: id
        },
        query: {
          PROJECT_GOV_MD5: md
        }
      })
      window._czc.push(['_trackEvent', '点击', '跳转到政采招标详情页'])
    },
    // 跳转到全球科研项目详情页
    toGlobalResearchDetail (item) {
      localStorage.setItem('navActive', 4)
      this.$store.commit('setnavActive', 4)
      const id = item.PROJECT_ID
      const uid = this.$store.state.userid
      const params = {
        title_id: id,
        user_id: uid
        // type: 1
      }
      Addhistoricalrecord(params).then((res) => {
        console.log('添加历史记录成功', res)
      }, (err) => {
        console.log('添加历史记录异常', err)
      })
      let md = item.PROJECT_MD5
      if (md === null) {
        md = ''
      }
      this.$router.push({
        name: 'reportingGuidelinesDetails',
        params: {
          id: id
        },
        query: {
          PROJECT_GOV_MD5: md
        }
      })
      window._czc.push(['_trackEvent', '点击', '跳转到科研项目详情页'])
    },
    // 跳转到更多文章详情页
    depthOfTheArticleDetails (id) {
      localStorage.setItem('navActive', 5)
      this.$store.commit('setnavActive', 5)
      this.$router.push({
        path: `reportingGuidelinesDetails${id}`
      })
      window._czc.push(['_trackEvent', '点击', '跳转到更多文章详情页'])
    },
     // 跳转到我收藏的文章
    tomyCollectionarticles() {
      this.$router.push({
        path: "myCollectionarticles",
      });
      window._czc.push(["_trackEvent", "点击", "跳转到我的收藏"]);
    },
    // 跳转到我近期浏览过的信息
    tomyRecentInformation() {
      this.$router.push({
        path: "myRecentInformation",
      });
      window._czc.push(["_trackEvent", "点击", "跳转到我近期浏览过的信息"]);
    },
  }
}

</script>

<style lang="scss" scoped>
.vertical-line {
  border-radius: 2.5px;
  width: 5px;
  height: 18px;
  background-color: #9702a3;
  margin-right: 4px;
}
// 版心
.background {
  background-color: #f1f2f6;
  overflow: hidden;
}
.show {
  display: block !important;
}
.typeArea {
  width: 1200px;
  margin: 0 auto;
}
.content {
  margin-top: 27px;
  .content-top {
    background-color: #fff;
    box-sizing: border-box;
    padding: 40px;
    padding-bottom: 29px;
    margin-bottom: 20px;
    .content-item {
      width: 100%;
      margin: 0 auto;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .header-left {
          display: flex;
          justify-content: left;
          align-items: center;
          div:nth-of-type(2) {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 28px;
            color: #5e068c;
            opacity: 1;
          }
        }
        .header-right {
          cursor: pointer;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 20px;
          color: #9d9d9d;
          opacity: 1;
          span {
            color: #990263;
          }
        }
      }
      .content-main {
        table {
          cursor: pointer;
          width: 100%;
          border-spacing: 0px;
          border-collapse: collapse;
          table-layout: fixed;
          thead {
            tr {
              height: 55px;
              border-bottom: 1px solid #f0f0f0;
              td {
                box-sizing: border-box;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: bold;
                line-height: 21px;
                color: #3b3b3b;
                opacity: 1;
                background-color: #f9f9f9;
              }
              td:nth-of-type(1) {
                text-align: left;
                padding-left: 30px;
                width: 500px;
              }
              td:nth-of-type(2) {
                width: 250px;
              }
              td:nth-of-type(3) {
                text-align: left;
              }
              td:nth-of-type(4) {
                text-align: center;
              }
            }
          }
          tbody {
            tr {
              cursor: pointer;
              height: 60px;
              border-bottom: 1px solid #f0f0f0;
              box-sizing: border-box;
              &:hover {
                background-color: #f9f9f9;
              }
              td {
                box-sizing: border-box;
                font-size: 15px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #282828;
                opacity: 1;
              }
              td[class="asfo"] {
                color: #707070 !important;
              }
              td:nth-of-type(1) {
                text-align: left;
                padding-left: 30px;
                padding-right: 6px;
                font-size: 15px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                line-height: 18px;
                color: #990263;
                opacity: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              td:nth-of-type(2) {
                padding-right: 6px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              td:nth-of-type(3) {
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              td:nth-of-type(4) {
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
    .project {
      .content-main {
        table {
          thead {
            tr {
              td {
              }
              td:nth-of-type(1) {
                text-align: left;
                padding-left: 30px;
                width: 500px;
              }
              td:nth-of-type(2) {
                width: 150px;
              }
              td:nth-of-type(3) {
                width: 150px;
                text-align: left;
                padding-right: 6px;
              }
              td:nth-of-type(4) {
                width: 150px;
                text-align: left;
              }
              td:nth-of-type(5) {
                text-align: center;
              }
            }
          }
          tbody {
            tr {
              cursor: pointer;
              height: 60px;
              border-bottom: 1px solid #f0f0f0;
              box-sizing: border-box;
              &:hover {
                background-color: #f9f9f9;
              }
              td {
                box-sizing: border-box;
                font-size: 15px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #282828;
                opacity: 1;
              }
              td[class="asfo"] {
                color: #707070 !important;
              }
              td:nth-of-type(1) {
                text-align: left;
                padding-left: 30px;
                padding-right: 6px;
                font-size: 15px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                line-height: 18px;
                color: #990263;
                opacity: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              td:nth-of-type(2) {
                padding-right: 6px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              td:nth-of-type(3) {
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              td:nth-of-type(4) {
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              td:nth-of-type(5) {
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
  .nocollection {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.z_xian{font-size: 14px;color: #666666;margin: 0 10px;}
.z_index{width: 100%;display: flex;justify-content: space-between;
  .left{width: calc(100% - 440px);}
  .right{width: 340px;}
}
.z_xgkt{width: 100%;box-sizing:border-box;padding:20px 40px;background: #fff;margin-bottom:170px;
  .title{font-size:18px;color:#333333;display:flex;align-items: center;margin-bottom:30px;
    &::before{content: "";display: block;width: 4px;
height: 12px;
background: #5E068C;
border-radius: 2px 2px 2px 2px;
opacity: 1;margin-right:10px;}
  }
  ul{width: 100%;margin:0;padding:0;
    li{width: 100%;line-height:1.5;margin:0;padding:0;list-style-type: none;margin-bottom:20px;cursor: pointer;
      h1{margin:0;margin-bottom:10px;font-size:14px;color:#282828;width: 100%;font-weight: normal;}
      h2{margin: 0;font-size: 14px;color:#AAAAAA;width: 100%;font-weight: normal;}
    }
  }
}

.z_nav{width: 100%;background:#fff;margin-bottom:35px;
  .ul{display: flex;height: 50px;
    .a{display: flex;align-items: center;height:100%;position: relative;font-size:14px;color:#666666;margin-right:40px;cursor: pointer;
      &.active{color: #5E068C;
        &::before{content: "";position: absolute;left: 0;right: 0;margin: 0 auto;bottom: 0;z-index: 9;width: 21px;height: 2px;background: #5E068C;}
      }
    }
  }
}

.content-item1 {
        display: flex;
                  margin-bottom: 25px;
          border-bottom: 1px solid #f0f0f0;
          padding-bottom:25px;
          &:last-child {
            border-bottom: 0;
          }
        .item-tag {
          width: 45px;
          height: 20px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 11px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
        }
        .keti {
          border: 1px solid #990263;
          color: #990263;
          width: 49px;
          height: 23px;
        }
        .zhaobiao {
          border: 1px solid #5e068c;
          color: #5e068c;
          width: 49px;
          height: 23px;
        }
        .jiezhi {
          border: 1px solid #707070;
          color: #707070;
          width: 49px;
          height: 23px;
        }
        .jinxing {
          border: 1px solid #990099;
          color: #990099;
          width: 49px;
          height: 23px;
        }
        .item-content {
          flex: 1;
          display: flex;
          flex-direction: column;
          .content-title {
            display: flex;
            cursor: pointer;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #282828;
            margin-bottom: 20px;
            line-height: 1.5;
            .title-text {
              flex: 1;
              color: #000;
              font-weight: bold;
            }
          }
          .content-info {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;
            .info-item {
              display: flex;
              margin-right: 48px;
              margin-bottom: 12px;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #666666;
              overflow: hidden;
              white-space: nowrap;
              .fbjg {
                display: block;
                width: 166px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .long {
              width: 240px;
              max-width: 240px;
            }
          }
          .content-tag {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .tag-item {
              padding: 6px;
              background-color: #f5f6fa;
              margin-right: 10px;
              border-radius: 1px;
              margin-bottom: 10px;
            }

            .checked {
              background-color: #5e068c;
              color: #fff;
            }
          }
        }
      }
      .re{
          width: 16px;
          height: 16px;
          background: linear-gradient(138deg, #FF9B9A 0%, #F44C41 100%);
          border-radius: 2px 2px 2px 2px;
          padding: 1px;
          opacity: 1;
          font-size: 10px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26px;
          -webkit-text-stroke: 1px rgba(0,0,0,0);
          margin: 0 6px 0 0;
        }
        .zhi{
          width: 16px;
          height: 16px;
          background: linear-gradient(138deg, #9F9F9F 0%, #757575 100%);
          border-radius: 2px 2px 2px 2px;
          padding: 1px;
          opacity: 1;
          font-size: 10px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26px;
          -webkit-text-stroke: 1px rgba(0,0,0,0);
          margin: 0 6px 0 0;
        }
        .button-see{
          cursor: pointer;
        }
</style>
